<template>
  <div >
    <div v-if="u.id > 0" @click="dialogVisible=true" class="flex-def flex-cCenter">
      <div v-if="!disabled_tag" style="margin-right: .5rem;width: 3rem">{{ u.level_label }}</div>
      <el-button type="text">
        变动<span v-if="disabled_tag">级别</span>
      </el-button>
    </div>

    <el-dialog custom-class="custom" append-to-body title="变动用户级别" :show-close="false" :visible.sync="dialogVisible"
               width="50rem">
      <div class="flex-cCenter flex-def flex-zCenter">
        <el-select v-model="value" placeholder="请选择">
          <el-option
              v-for="item in doseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button type="success" style="margin-left: 2rem" @click="sure">确认变更</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "y_member_up_level",
  props: {
    uid: {
      type: Number,
      default: 0
    },
    disabled_tag:{
      type:Boolean,
      default: false
    }
  },
  computed:{
    doseOptions(){
      let ad = this.$store.state.sys.global.addons
      if(ad.chain)return this.chain_options;
      if (ad.area_agent)return this.area_agent_options;
      return[]
    }
  },
  data() {
    return {
      list: [],
      dialogVisible: false,
      value:0,
      u:{},
      area_agent_options:[
        {
          label:"普通用户",
          value:0,
        },
        {
          label:"会员",
          value:1,
        },
        {
          label:"乡镇代理",
          value:2,
        },
        {
          label:"区县代理",
          value:3,
        },
        {
          label:"市级代理",
          value:4,
        },
        {
          label:"省级代理",
          value:5,
        },
        {
          label:"合伙人",
          value:6,
        },
      ],
      chain_options:[
        {
          label:"会员",
          value:0,
        },
        {
          label:"代理",
          value:1,
        },
        {
          label:"老板",
          value:2,
        },
        {
          label:"总监",
          value:3,
        }
      ],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    sure(){
      if(this.value === this.u.level)return this.$message.info("级别未变动");
      this.$api.member.account.selLevel({uid:this.uid,level:this.value}).then(()=>{
        this.$message.success("操作成功");
        this.dialogVisible = false;
        this.$emit("reload")
        this.load();
      })
    },
    load() {
      this.$api.member.account.one({id:this.uid}).then(res=>{
        this.u = res;
        this.value = res.level
      })
    },
  }
}
</script>

<style scoped>

</style>